import styled from '@emotion/styled'
import { Accordion } from 'app/components/Common/Accordion'
import { Props as ItemProps } from 'app/components/Common/Accordion/item'
import { AnimatedTitle } from 'app/components/Common/Animation/AnimatedTitle'
import React, { memo } from 'react'

export interface Props {
  items: ItemProps[]
  title?: string
}

export const BlockAccordion = memo(function BlockAccordion({
  items,
  title,
}: Props) {
  return (
    <Container>
      {title ? <Title text={title} /> : null}
      {items ? <Accordion items={items} /> : null}
    </Container>
  )
})

const Container = styled.section`
  margin: 8vw 0;
  padding: 0 10vw;

  @media (max-width: 991px) {
    margin: 0;
    padding: 0 30px;
  }
`

const Title = styled(AnimatedTitle)`
  position: relative;
  display: block;
  padding-left: 3.5vw;
  margin-bottom: 2.08vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 2.77vw;
  line-height: 1.15;
  background: url('/star.svg') center left no-repeat;
  background-size: 2.11vw auto;

  @media (max-width: 991px) {
    font-size: 28px;
    background-size: 20px auto;
    padding-left: 30px;
    margin-bottom: 20px;
  }
`
